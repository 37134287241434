import { render, staticRenderFns } from "./Customer.vue?vue&type=template&id=992eaa6e&"
import script from "./Customer.vue?vue&type=script&lang=js&"
export * from "./Customer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rmonvaldez/Documents/PROYECTOS/JuanGabriel_POS/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('992eaa6e')) {
      api.createRecord('992eaa6e', component.options)
    } else {
      api.reload('992eaa6e', component.options)
    }
    module.hot.accept("./Customer.vue?vue&type=template&id=992eaa6e&", function () {
      api.rerender('992eaa6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/Customer.vue"
export default component.exports