<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
<div>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12">
                            <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                <div class="card-header align-items-center  border-bottom-dark px-0">
                                    <div class="card-title mb-0">
                                        <h3 class="card-label mb-0 font-weight-bold text-body">
                                            Stock de tickets
                                        </h3>
                                    </div>
                                     <div class="icons d-flex">
                                        <div class="btn ml-2 p-0 kt_notes_panel_toggle" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos">
                                            <span class="bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm " v-on:click="
                                                display_form_add_day = !display_form_add_day
                                                ">
                                                <svg width="25px" height="25px" viewBox="0 0 16 16" class="bi bi-plus white" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 ">
                            <div class="card card-custom gutter-b bg-white border-0">
                                <div class="card-body">
                                    <div>
                                        <div class=" table-responsive" id="printableTable">
                                        
                                            <div id="sale_wrapper" class="dataTables_wrapper no-footer">

                                            <div class="dataTables_length" id="sale_length"><label>Show 
                                            <select name="sale_length"  class="" v-model="daysLimit" v-on:change="fetchDays()">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                            </select> entries</label></div>

                                            <div id="sale_filter" class="dataTables_filter">
                                                <label>Search:<input type="text" class="" placeholder=""  v-model="daysSearchParameter" @keyup="fetchDays()"></label>
                                                <button v-if="this.daysSearchParameter != ''" @click="clearSearchDays">clear</button>
                                                </div>
                                                <table id="sale" class="display dataTable no-footer" sale="grid">
                                                    <thead class="text-body">
                                                        <tr sale="row">
                                                            
                                                            <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;" @click="daysSorting('id')" :class="(this.$data.daysSortType == 'asc' || this.$data.daysSortType == 'ASC') && this.$data.daysSortBy == 'id'  ? 'sorting_asc' : (this.$data.daysSortType == 'desc' || this.$data.daysSortBy == 'DESC') && this.$data.daysSortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                ID
                                                            </th>
                                                            <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                                                                 Fecha
                                                             </th>

                                                           <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;" @click="daysSorting('created_at')" :class="(this.$data.daysSortType == 'asc' || this.$data.daysSortType == 'ASC') && this.$data.dasySortBy == 'created_at'  ? 'sorting_asc' : (this.$data.daysSortType == 'desc' || this.$data.daysSortType == 'DESC') && this.$data.dasySortBy == 'created_at' ? 'sorting_desc' : 'sorting'">
                                                                Total de boletos
                                                            </th>
                                                            <tH class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;">
                                                                Boletos vendidos
                                                            </tH>
                                                            <tH class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;">
                                                                Horarios activos
                                                            </tH>
                                                            <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Día gratuito
                                                            </th>
                                                            <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Horarios
                                                            </th>
                                                            <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Opciones
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="kt-table-tbody text-dark">
                                                        <tr class="kt-table-row kt-table-row-level-0 odd" sale="row" v-for="dayItem in days" v-bind:key="day.id">
                                                            <td>
                                                                {{ dayItem.id }}
                                                            </td>
                                                            <td>
                                                                {{ formatDate(dayItem.date) }}
                                                            </td>
                                                            <td>
                                                                {{ dayItem.total_tickets }}
                                                            </td>
                                                            <td>
                                                                {{ dayItem.sold_tickets }}
                                                            </td>
                                                            <td>
                                                                {{ dayItem.total_available_hours }}
                                                            </td>
                                                            <td>
                                                                <span v-if="dayItem.is_free_day == 1">Sí</span>
                                                                <span v-else>No</span>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div v-for="hour of dayItem.available_hours">
                                                                        <div v-if="hour.status === 1" class="flex">
                                                                            <small>{{ formatTime(hour.time) }}</small>
                                                                            <small>Vendidos: <strong style="color: green;">{{ hour.soldTickets }}</strong></small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="text-center">
                                                                <button class="btn btn-sm btn-icon btn-light btn-hover-primary" v-on:click="showDayHours(dayItem)">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M12 7V12L14.5 10.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </button>
                                                                <button class="btn btn-sm btn-icon btn-light btn-hover-primary" v-on:click="editDay(dayItem)">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </button>
                                                                <button class="btn btn-sm btn-icon btn-light btn-hover-primary" v-on:click="removeDay(dayItem.id)">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <ul class="pagination pagination-sm m-0 float-right">
                                                    <li v-bind:class="[{disabled: !pagination.prev_page_url}]"><button class="page-link" href="#" @click="fetchDays(pagination.prev_page_url)">Previous</button></li>

                                                    <li class="disabled"><button class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</button></li>

                                                    <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item"><button class="page-link" href="#" @click="fetchDays(pagination.next_page_url)">Next</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel" v-if="display_form_add_day" :class="display_form_add_day ? 'offcanvas-on' : ''">
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 class="font-size-h4 font-weight-bold m-0">Agregar días</h4>
            <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="display_form_add_day = 0">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </a>
        </div>
        <form id="myform">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="text-dark">Calendario</label>
                        <!-- <input type="date" name="time" v-model="day.date" class="form-control" /> -->
                        <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            >
                            <v-date-picker
                                v-model="day.date"
                                locale="es"
                                no-title
                                multiple
                            ></v-date-picker>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="6"
                            >
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="day.date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                    v-model="day.date"
                                    multiple
                                    chips
                                    small-chips
                                    label="Días seleccionados"
                                    prepend-icon="mdi-calendar"
                                    disabled
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-combobox>
                                </template>
                            </v-menu>
                            </v-col>
                        </v-row>
                        <small class="form-text text-danger" v-if="errors.has('date')" v-text="errors.get('date')"></small>
                    </div>
                </div>
            </div>
            <button type="button" @click="addUpdateDays()" class="btn btn-primary" style="margin-top: 1rem;">Guardar</button>
        </form>
    </div>
      <div class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel" v-if="display_form_update_day" :class="display_form_update_day ? 'offcanvas-on' : ''">
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 class="font-size-h4 font-weight-bold m-0">Actualizar día</h4>
            <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="display_form_update_day = 0">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </a>
        </div>
        <form id="myform">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="text-dark">Día</label>
                        <input type="date" name="time" v-model="updateDay.date" class="form-control" />
                        <small class="form-text text-danger" v-if="errors.has('date')" v-text="errors.get('date')"></small>
                    </div>
                    <div class="form-group">
                        <label class="text-dark">¿Acceso gratuito?</label>
                        <select name="is_default" v-model="updateDay.is_free_day" class="form-control">
                            <option value="0">No</option>
                            <option value="1">Sí</option>
                        </select>
                        <small class="form-text text-danger" v-if="errors.has('is_free_day')" v-text="errors.get('is_free_day')"></small>
                    </div>
                    <div class="form-group">
                        <label class="text-dark">¿Habilitado?</label>
                        <select name="is_default" v-model="updateDay.status" class="form-control">
                            <option value="0">No</option>
                            <option value="1">Sí</option>
                        </select>
                        <small class="form-text text-danger" v-if="errors.has('status')" v-text="errors.get('status')"></small>
                    </div>
                </div>
            </div>
            <button type="button" @click="addUpdateDays()" class="btn btn-primary" style="margin-top: 1rem;">Guardar</button>
        </form>
    </div>
    <div class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel" v-if="display_form_add_hour" :class="display_form_add_hour ? 'offcanvas-on' : ''">
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 class="font-size-h4 font-weight-bold m-0">Horarios disponibles</h4>
            <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="display_form_add_hour = 0">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </a>
        </div>
        <form id="myform">
            <div class="row" v-for="hourItem in hoursForm">
                <div class="col-12" style="display: flex; justify-content: space-around;align-items: center;">
                    <div class="form-group">
                        <label class="text-dark">Estatus</label>
                        <input type="checkbox" name="set" v-model="hourItem.status" class="form-control" style="transform: scale(1);" />
                    </div>
                    <div class="form-group" >
                        <label class="text-dark">Hora</label>
                        <input type="time" name="time" v-model="hourItem.time" readonly class="form-control" />
                    </div>
                    <div class="form-group">
                        <label class="text-dark">Tickets</label>
                        <input type="number" step="1" name="tickets" v-model="hourItem.tickets" min="1" class="form-control"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Boletos</label>
                        <multiselect v-model="hourItem.product_ids" 
                        :options="products" 
                        placeholder="Selecciona una opción" 
                        label="title" 
                        track-by="product_id"
                        :multiple="true"
                        :key="hourItem.id"
                        >
                        </multiselect>
                        <small
                        class="form-text text-danger"
                        v-if="errors.has('product_ids')"
                        v-text="errors.get('product_ids')"
                        ></small>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 2rem;">
                <button type="button" @click="addUpdateHours()" class="btn btn-primary">Guardar</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import ErrorHandling from "../../ErrorHandling";
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            products: [],
            menu: false,
            display_form_add_day: 0,
            display_form_update_day: 0,
            display_form_add_hour: 0,
            days:[],
            hours:[],
            day: {},
            dayHours: 0,
            updateDay: {},
            hour: {},
            hoursForm: [],
            daysSearchParameter: '',
            daysSortBy:'id',
            daysSortType:'DESC',
            daysLimit: 10,
            error_message: '',
            edit: false,
            actions: false,
            pagination: {},
            request_method: "",
            is_default: "0",
            token: [],
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        };
    },

    methods: {
        fetchDays(page_url) {
            page_url = page_url ||  "/api/admin/days";
            this.$parent.loading = true;
            let vm = this;
            var arr = page_url.split('?');
            
            if (arr.length > 1) {
                page_url += '&limit='+this.daysLimit;
            }
            else{
                page_url += '?limit='+this.daysLimit;
            }
            if(this.daysSearchParameter != null){
                page_url += '&searchParameter='+this.daysSearchParameter;
            }
            page_url += '&sortBy='+this.daysSortBy+'&sortType='+this.daysSortType;

            axios.get(page_url, this.token).then(res => {
                this.days = res.data.data;
                if(this.days.length > 0){
                    vm.makePagination(res.data.meta, res.data.links);
                }
            })
            .finally(() => (this.$parent.loading = false));
        },
        editDay(day){
            this.edit = true;
            this.errors = new ErrorHandling();
            this.updateDay = day;
            console.log('day',this.updateDay);
            this.display_form_update_day = 1;
        },
        fectProducts(){
            let page_url = "/api/admin/tickets-products";
            axios.get(page_url, this.token).then(res => {
                this.products = res.data;
            });
        },
        editHour(hour){
            this.edit = true;
            this.errors = new ErrorHandling();
            this.hour = hour;
            this.display_form_add_hour = 1;
        },
        fetchDayHours(id){
            let page_url = "/api/admin/days/"+id+"/hours";
            axios.get(page_url, this.token).then(res => {
                this.hoursForm = res.data;
            })
            .finally(() => (this.$parent.loading = false));
        },
        addUpdateDays() {
            this.$parent.loading = true;
            var url = '/api/admin/days';
            let date = this.day.date;
            if (this.edit === false) {
                // Add
                this.request_method = 'post'
            } else {
                // Update
                var url = '/api/admin/days/' + this.updateDay.id;
                this.request_method = 'put'
                date = this.updateDay.date;
            }

            axios[this.request_method](url, {
                        'date': date,
                        'is_free_day': this.updateDay.id ? this.updateDay.is_free_day : this.day.is_free_day,
                        'status': this.updateDay.id ? this.updateDay.status : this.day.status,
                    },
                    this.token)
                .then(res => {
                    if (res.data.insert_status) {
                        this.display_form_add_day = 0;
                        this.display_form_update_day = 0;
                        this.$toaster.success(res.data.message)
                        this.fetchDays();
                        this.clearDayForm();
                    } else {
                        this.$toaster.error(res.data.message)
                    }
                    if(this.edit){
                        this.edit = false;
                    }
                })
                .catch(error => {
                    this.error_message = '';
                    this.errors = new ErrorHandling();
                    if (error.response.status == 422) {
                        if (error.response.data.status == 'Error') {
                            this.error_message = error.response.data.message;
                        } else {
                            this.errors.record(error.response.data.errors);
                        }
                    }
                }).finally(() => (this.$parent.loading = false));
        },
        addUpdateHours() {
            console.log('day in save hour method',this.dayHours);
            this.$parent.loading = true;
            var url = '/api/admin/days/'+this.dayHours.id+'/hours';
            if (this.edit === false) {
                // Add
                this.request_method = 'post'
            } else {
                // Update
                var url = '/api/admin/days/' + this.dayHours.id + '/hours/' + this.hour.id;
                this.request_method = 'put'
            }
            axios[this.request_method](url, {
                        'times': this.hoursForm,
                    },
                    this.token)
                .then(res => {
                    if (res.data.status == true) {
                        this.display_form_add_hour = 0;
                        this.$toaster.success(res.data.message)
                        this.fetchDayHours(this.dayHours.id);
                        this.clearHourForm();
                        this.fetchDays();
                    } else {
                        this.$toaster.error(res.data.message)
                    }
                    if(this.edit){
                        this.edit = false;
                    }
                })
                .catch(error => {
                    this.error_message = '';
                    this.errors = new ErrorHandling();
                    if (error.response.status == 422) {
                        if (error.response.data.status == 'Error') {
                            this.error_message = error.response.data.message;
                        } else {
                            this.errors.record(error.response.data.errors);
                        }
                    }
                }).finally(() => (this.$parent.loading = false));
        },
        clearDayForm(){
            this.edit = false;
            this.display_form_add_day= 0;
            this.display_form_update_day= 0;
            this.day = {};
            this.updateDay = {};
        },
        clearHourForm(){
            this.edit = false;
            this.display_form_add_hour= 0;
            this.hour = {};
            this.dayHours = 0;
        },
        showDayHours(day){
            this.dayHours = day;
            console.log('day',this.dayHours);
            this.display_form_add_hour = 1;
            if(day.available_hours.length > 0){
                this.hoursForm = day.available_hours;
            }else{
                this.hoursForm = [];
                this.generateHoursArray();
            }
        },
        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next,
                prev_page_url: links.prev,
            };

            this.pagination = pagination;
        },
        removeDay(day_id){
            if(confirm('¿Seguro que quieres eliminar este elemento?')){
                this.$parent.loading = true;
                var url = '/api/admin/days/' + day_id;
                axios.delete(url, this.token)
                .then(res => {
                    this.$toaster.success(res.data.message)
                    this.fetchDays();
                })
                .catch(error => {
                    this.$toaster.error(error.response.data.message)
                }).finally(() => (this.$parent.loading = false));
            }
        },
        daySorting(sortBy){
            this.daysSortBy = sortBy;
            this.daysSortType = this.daysSortType == 'asc' || this.daysSortType == 'ASC' ? this.daysSortType='desc' : this.daysSortType = 'asc';
            this.fetchDays();
        },
        hourSorting(sortBy){
            this.hoursSortBy = sortBy;
            this.hoursSortType = this.hoursSortType == 'asc' || this.hoursSortType == 'ASC' ? this.hoursSortType='desc' : this.hoursSortType = 'asc';
            this.fetchDays();
        },
        clearSearchDays(){
            this.daysSearchParameter = "",
            this.fetchDays();
        },
        formatDate(dateString) {
            //convert format yyyy-mm-dd to mm-dd-yyyy
            const [year, month, day] = dateString.split('-');
            dateString =  `${month}-${day}-${year}`;
            const date = new Date(dateString);

            const options = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            };

            const formatter = new Intl.DateTimeFormat('es-ES', options);
            return formatter.format(date);
        },
        formatTime(timeString) {
            //format time from 24h to 12h
            const [hour, minute, second] = timeString.split(':');
            let hourFormatted = hour % 12 || 12;
            let period = hour < 12 ? 'AM' : 'PM';
            return `${hourFormatted}:${minute} ${period}`;
        },
        generateHoursArray() {
            for (let hour = 8; hour <= 22; hour+=0.5) {
                let hourFormatted = Math.floor(hour).toString().padStart(2, '0');
                let minutes = (hour % 1 === 0) ? '00' : '30';
                let time = `${hourFormatted}:${minutes}:00`;
                this.hoursForm.push({ time, tickets: 0, status: false });
            }
            this.fectProducts();
        },
    },
    mounted() {
     
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchDays();
        this.generateHoursArray();
    },
    props: ['loading'],
};
</script>
