var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid h-100 bg-image",
      staticStyle: {
        "background-image": "url(/assets/images/misc/bg-login.png)",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center align-items-center h-100",
        },
        [
          _c("div", { staticClass: "row w-100 justify-content-center" }, [
            _c("div", { staticClass: "col-12 col-md-8 col-lg-6 col-xl-4" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 mb-0 p-5 login-card",
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body p-0" }, [
                    _c(
                      "form",
                      {
                        staticClass: "pb-5 pt-5",
                        attrs: { id: "myform" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.login.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group  row" }, [
                          _c("div", { staticClass: "col-lg-2 col-3 " }, [
                            _c(
                              "label",
                              {
                                staticClass: "mb-0 text-dark",
                                attrs: { for: "email" },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "bi bi-person",
                                    attrs: {
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 16 16",
                                      fill: "currentColor",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        d: "M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-10 col-9 pl-0" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email",
                                },
                              ],
                              staticClass:
                                "form-control bg-transparent text-dark border-0 p-0 h-20px font-size-h5",
                              attrs: {
                                type: "email",
                                name: "email",
                                placeholder: "example@mail.com",
                                id: "email",
                                "aria-describedby": "emailHelp",
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("email")
                              ? _c("span", {
                                  staticClass: "help is-danger text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("email")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row " }, [
                          _c("div", { staticClass: "col-lg-2 col-3 " }, [
                            _c(
                              "label",
                              {
                                staticClass: "mb-0 text-dark",
                                attrs: { for: "password" },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "bi bi-lock",
                                    attrs: {
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 16 16",
                                      fill: "currentColor",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        d: "M11.5 8h-7a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-7-1a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-7zm0-3a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-10 col-9 pl-0" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              staticClass:
                                "form-control text-dark bg-transparent font-size-h4 border-0 p-0 h-20px",
                              attrs: {
                                type: "password",
                                name: "password",
                                placeholder: ".......",
                                id: "password",
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.password = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("password")
                              ? _c("span", {
                                  staticClass: "help is-danger text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("password")
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.error_message
                              ? _c(
                                  "span",
                                  { staticClass: "help is-danger text-danger" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.error_message) +
                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary text-white font-weight-bold w-100 py-3",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tLogin\n\t\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header align-items-center  justify-content-center border-0 h-100px flex-column",
        staticStyle: { "flex-flow": "column !important" },
      },
      [
        _c("div", { staticClass: "card-title mb-0" }, [
          _c(
            "h3",
            { staticClass: "card-label font-weight-bold mb-0 text-body" },
            [
              _c("img", {
                staticStyle: { "max-width": "200px !important" },
                attrs: { src: "/assets/images/misc/logo.png", alt: "logo" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "font-size-h5 mb-0 mt-3 text-dark" }, [
          _vm._v(
            "\n\t\t\t\t\t\t\t\tPlease login to your account.\n\t\t\t\t\t\t\t"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }