<template>
    <form action="#" method="post" id="form-terms">
        <div class="form-group row">
            <div class="col-12">
                <label>Términos y condiciones</label>
                <div class="form-group ">
                    <vue-editor v-model="terms_conditions"></vue-editor>
                </div>
            </div>
            <div class="col-md-12">
                <br />
                <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
        </div>
    </form>
</template>
<script>
import { VueEditor } from "vue2-editor";
    export default {
        components: {
            VueEditor
        },
        data() {
            return {
                terms_conditions: ''
            }
        },
        methods: {
            getTermsConditions() {
                var token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                axios.get('/api/admin/terms-conditions', config)
                    .then(response => {
                        console.log('terms and conditions',response.data);
                        this.terms_conditions = response.data.data;
                    })
                    .catch(error => {
                        ErrorHandling(error);
                    });
            },
            updateTermsAconditions() {
                var token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                axios.post('/api/admin/terms-conditions', {terms_conditions: this.terms_conditions}, config)
                    .then(response => {
                        alert('Guardado correctamente');
                    })
                    .catch(error => {
                        console.log('error', error);
                    });
            },
        },
        mounted() {
            this.getTermsConditions();
            const form = document.getElementById('form-terms');
            form.addEventListener('submit', (e) => {
                e.preventDefault();
                this.updateTermsAconditions();
            }); 
        }
    }
    </script>