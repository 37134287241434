var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-custom gutter-b bg-white border-0" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "ul",
              {
                staticClass: "nav flex-column nav-pills mb-3",
                attrs: {
                  id: "v-pills-tab1",
                  role: "tablist",
                  "aria-orientation": "vertical",
                },
              },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("general") },
                      attrs: { href: "#general" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setActive("general")
                        },
                      },
                    },
                    [_vm._v("General")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("theme") },
                      attrs: { href: "#theme" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setActive("theme")
                        },
                      },
                    },
                    [_vm._v("Theme Setting")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("seo") },
                      attrs: { href: "#seo" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setActive("seo")
                        },
                      },
                    },
                    [_vm._v("SEO Settings")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("loginsignup") },
                      attrs: { href: "#loginsignup" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setActive("loginsignup")
                        },
                      },
                    },
                    [_vm._v("Login / Signup")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("sociallitelogin") },
                      attrs: { href: "#sociallitelogin" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setActive("sociallitelogin")
                        },
                      },
                    },
                    [_vm._v("Sociallite Login")]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-9" }, [
            _c(
              "div",
              {
                staticClass: "tab-content",
                attrs: { id: "v-pills-tabContent1" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("general") },
                  },
                  [
                    _c("WebsiteGeneral", {
                      on: { updateLoadingState: _vm.updateLoadingState },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("theme") },
                  },
                  [
                    _c("Theme", {
                      on: { updateLoadingState: _vm.updateLoadingState },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("seo") },
                  },
                  [
                    _c("SEO", {
                      on: { updateLoadingState: _vm.updateLoadingState },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("loginsignup") },
                  },
                  [
                    _c("LoginSignup", {
                      on: { updateLoadingState: _vm.updateLoadingState },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("sociallitelogin") },
                  },
                  [
                    _c("SocialliteLogin", {
                      on: { updateLoadingState: _vm.updateLoadingState },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("Website Setting\n        ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }