<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Boletos
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class=" table-responsive" id="printableTable">
                                            
                                                <div id="sale_wrapper" class="dataTables_wrapper no-footer">
    
                                                <div class="dataTables_length" id="sale_length"><label>Show 
                                                <select name="sale_length"  class="" v-model="daysLimit" v-on:change="fetchTickets()">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                </select> entries</label></div>
    
                                                <div id="sale_filter" class="dataTables_filter">
                                                    <label>
                                                        Fecha: <input type="date" class="" placeholder=""  v-model="daysSearchDateParameter">
                                                    </label>
                                                    <label>Hora: <input type="time" class="" placeholder="" v-model="daysSearchTimeParameter"></label>
                                                    <label>Search:<input type="text" class="" placeholder=""  v-model="daysSearchParameter" @keyup="fetchTickets()"></label>
                                                    <button class="btn btn-primary" style="max-height: 33px; padding: 10px; align-items: center; display: inline-flex;" @click="fetchTickets()">Buscar</button>
                                                    <button v-if="daysSearchParameter != '' || daysSearchDateParameter !== '' || daysSearchTimeParameter != ''"  class="btn btn-secondary" style="max-height: 33px; padding: 10px; align-items: center; display: inline-flex;" @click="clearFilters()">Cancelar</button>
                                                    </div>
                                                    <table id="sale" class="display dataTable no-footer" sale="grid">
                                                        <thead class="text-body">
                                                            <tr sale="row">
                                                                
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;" @click="daySorting('id')" :class="(this.$data.daysSortType == 'asc' || this.$data.daysSortType == 'ASC') && this.$data.daysSortBy == 'id'  ? 'sorting_asc' : (this.$data.daysSortType == 'desc' || this.$data.daysSortBy == 'DESC') && this.$data.daysSortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                    ID
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                                                                     Código
                                                                 </th>
    
                                                               <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;" @click="daySorting('created_at')" :class="(this.$data.daysSortType == 'asc' || this.$data.daysSortType == 'ASC') && this.$data.dasySortBy == 'created_at'  ? 'sorting_asc' : (this.$data.daysSortType == 'desc' || this.$data.daysSortType == 'DESC') && this.$data.dasySortBy == 'created_at' ? 'sorting_desc' : 'sorting'">
                                                                    Correo
                                                                </th>
                                                                <tH class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" @click="daySorting('available_days.date')" :class="(this.$data.daysSortType == 'asc' || this.$data.daysSortType == 'ASC') && this.$data.daysSortBy == 'available_days.date'  ? 'sorting_asc' : (this.$data.daysSortType == 'desc' || this.$data.daysSortBy == 'DESC') && this.$data.daysSortBy == 'available_days.date' ? 'sorting_desc' : 'sorting'">
                                                                    Fecha
                                                                </tH>
                                                                <tH class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" style="width: 53.1891px;" @click="daySorting('available_hours.time')" :class="(this.$data.daysSortType == 'asc' || this.$data.daysSortType == 'ASC') && this.$data.daysSortBy == 'available_hours.time'  ? 'sorting_asc' : (this.$data.daysSortType == 'desc' || this.$data.daysSortBy == 'DESC') && this.$data.daysSortBy == 'available_hours.time' ? 'sorting_desc' : 'sorting'">
                                                                    Hora
                                                                </tH>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                    Opciones
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" sale="row" v-for="dayItem in days" v-bind:key="day.id">
                                                                <td>
                                                                    {{ dayItem.id }}
                                                                </td>
                                                                <td>
                                                                    {{ dayItem.code }}
                                                                </td>
                                                                <td>
                                                                    {{ dayItem.order.length > 0 ? dayItem.order[0].email : '' }}
                                                                </td>
                                                                <td>
                                                                    {{ formatDate(dayItem.available_day.date) }}
                                                                </td>
                                                                <td>
                                                                    {{ dayItem.available_hour.time }}
                                                                </td>
                                                                <td class="text-center">
                                                                    <button class="btn btn-sm btn-icon btn-light btn-hover-primary" v-on:click="editDay(dayItem)">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[{disabled: !pagination.prev_page_url}]"><button class="page-link" href="#" @click="fetchTickets(pagination.prev_page_url)">Previous</button></li>

                                                        <li class="disabled"><button class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</button></li>

                                                        <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item"><button class="page-link" href="#" @click="fetchTickets(pagination.next_page_url)">Next</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
          <div class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel" v-if="display_form_update_day" :class="display_form_update_day ? 'offcanvas-on' : ''">
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
                <h4 class="font-size-h4 font-weight-bold m-0">Información del boleto</h4>
                <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="display_form_update_day = 0">
                    <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                </a>
            </div>
            <form id="myform">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="text-dark">Código</label>
                            <input type="text" name="code" :value="updateDay.code" disabled class="form-control" />
                        </div>
                        <div class="form-group">
                            <label class="text-dark">Correo</label>
                            <input type="text" name="email" :value="updateDay.order[0].email" disabled class="form-control" />
                        </div>
                        <div class="form-group">
                            <label class="text-dark">Fecha</label>
                            <input type="text" name="date" :value="formatDate(updateDay.available_day.date)" disabled class="form-control" />
                        </div>
                        <div class="form-group">
                            <label class="text-dark">Hora</label>
                            <input type="text" name="time" :value="updateDay.available_hour.time" disabled class="form-control" />
                        </div>
                        <div class="form-group">
                            <label class="text-dark">Estatus</label>
                            <input type="text" name="status" :value="updateDay.status" disabled class="form-control" />
                        </div>
                        <div class="form-group">
                            <label class="text-dark">Ultima actualización</label>
                            <input type="text" name="status" :value="formatDate2(updateDay.created_at)" disabled class="form-control" />
                        </div>
                        <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + updateDay.code">
                    </div>
                </div>
            </form>
        </div>
    </div>
    </template>
    
    <script>
    import ErrorHandling from "../../ErrorHandling";
    export default {
        data() {
            return {
                menu: false,
                display_form_update_day: 0,
                days:[],
                day: {},
                updateDay: {},
                daysSearchParameter: '',
                daysSearchDateParameter: '',
                daysSearchTimeParameter: '',
                daysSortBy:'id',
                daysSortType:'DESC',
                daysLimit: 10,
                error_message: '',
                edit: false,
                actions: false,
                pagination: {},
                request_method: "",
                is_default: "0",
                token: [],
                errors: new ErrorHandling(),
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            };
        },
    
        methods: {
            fetchTickets(page_url) {
                page_url = page_url || "/api/admin/tickets";
                this.$parent.loading = true;
                let vm = this;
                var arr = page_url.split('?');
                
                if (arr.length > 1) {
                    page_url += '&limit='+this.daysLimit;
                }
                else{
                    page_url += '?limit='+this.daysLimit;
                }
                if(this.daysSearchParameter != null){
                    page_url += '&searchParameter='+this.daysSearchParameter;
                }
                if(this.daysSearchDateParameter != null){
                    page_url += '&searchDateParameter='+this.daysSearchDateParameter;
                }
                if(this.daysSearchTimeParameter != null){
                    page_url += '&searchTimeParameter='+this.daysSearchTimeParameter;
                }
                page_url += '&sortBy='+this.daysSortBy+'&sortType='+this.daysSortType;
    
                axios.get(page_url, this.token).then(res => {
                    this.days = res.data.data;
                    if(this.days.length > 0){
                        vm.makePagination(res.data.meta, res.data.links);
                    }
                })
                .finally(() => (this.$parent.loading = false));
            },
            editDay(day){
                this.edit = true;
                this.errors = new ErrorHandling();
                this.updateDay = day;
                this.display_form_update_day = 1;
            },
            clearFilters(){
                this.daysSearchParameter = "";
                this.daysSearchDateParameter = "";
                this.daysSearchTimeParameter = "";
                this.fetchTickets();
            },
            makePagination(meta, links) {
                let pagination = {
                    current_page: meta.current_page,
                    last_page: meta.last_page,
                    next_page_url: links.next,
                    prev_page_url: links.prev,
                };

                this.pagination = pagination;
            },
            daySorting(sortBy){
                this.daysSortBy = sortBy;
                this.daysSortType = this.daysSortType == 'asc' || this.daysSortType == 'ASC' ? this.daysSortType='desc' : this.daysSortType = 'asc';
                this.fetchTickets();
            },
            clearSearchDays(){
                this.daysSearchParameter = "",
                this.fetchTickets();
            },
            formatDate(dateString) {
                //convert format yyyy-mm-dd to mm-dd-yyyy
                const [year, month, day] = dateString.split('-');
                dateString =  `${month}-${day}-${year}`;
                const date = new Date(dateString);
    
                const options = {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                };
    
                const formatter = new Intl.DateTimeFormat('es-ES', options);
                return formatter.format(date);
            },
            formatDate2(dateString){
                const date = new Date(dateString);
                const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
                const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
                return formattedDate;
            },
        },
        mounted() {
         
            var token = localStorage.getItem('token');
            this.token = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            this.fetchTickets();
        },
        props: ['loading'],
    };
    </script>
    