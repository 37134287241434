var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 " }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: " table-responsive",
                            attrs: { id: "printableTable" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "sale_wrapper" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "sale_length" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Show \n                                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.daysLimit,
                                              expression: "daysLimit",
                                            },
                                          ],
                                          attrs: { name: "sale_length" },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.daysLimit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                return _vm.fetchTickets()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" entries"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "sale_filter" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "\n                                                    Fecha: "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.daysSearchDateParameter,
                                            expression:
                                              "daysSearchDateParameter",
                                          },
                                        ],
                                        attrs: {
                                          type: "date",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: _vm.daysSearchDateParameter,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.daysSearchDateParameter =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v("Hora: "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.daysSearchTimeParameter,
                                            expression:
                                              "daysSearchTimeParameter",
                                          },
                                        ],
                                        attrs: {
                                          type: "time",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: _vm.daysSearchTimeParameter,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.daysSearchTimeParameter =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v("Search:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.daysSearchParameter,
                                            expression: "daysSearchParameter",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: _vm.daysSearchParameter,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.fetchTickets()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.daysSearchParameter =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        staticStyle: {
                                          "max-height": "33px",
                                          padding: "10px",
                                          "align-items": "center",
                                          display: "inline-flex",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fetchTickets()
                                          },
                                        },
                                      },
                                      [_vm._v("Buscar")]
                                    ),
                                    _vm._v(" "),
                                    _vm.daysSearchParameter != "" ||
                                    _vm.daysSearchDateParameter !== "" ||
                                    _vm.daysSearchTimeParameter != ""
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-secondary",
                                            staticStyle: {
                                              "max-height": "33px",
                                              padding: "10px",
                                              "align-items": "center",
                                              display: "inline-flex",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearFilters()
                                              },
                                            },
                                          },
                                          [_vm._v("Cancelar")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: { id: "sale", sale: "grid" },
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c(
                                        "tr",
                                        { attrs: { sale: "row" } },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              class:
                                                (this.$data.daysSortType ==
                                                  "asc" ||
                                                  this.$data.daysSortType ==
                                                    "ASC") &&
                                                this.$data.daysSortBy == "id"
                                                  ? "sorting_asc"
                                                  : (this.$data.daysSortType ==
                                                      "desc" ||
                                                      this.$data.daysSortBy ==
                                                        "DESC") &&
                                                    this.$data.daysSortBy ==
                                                      "id"
                                                  ? "sorting_desc"
                                                  : "sorting",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.daySorting("id")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                ID\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                 Código\n                                                             "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              class:
                                                (this.$data.daysSortType ==
                                                  "asc" ||
                                                  this.$data.daysSortType ==
                                                    "ASC") &&
                                                this.$data.dasySortBy ==
                                                  "created_at"
                                                  ? "sorting_asc"
                                                  : (this.$data.daysSortType ==
                                                      "desc" ||
                                                      this.$data.daysSortType ==
                                                        "DESC") &&
                                                    this.$data.dasySortBy ==
                                                      "created_at"
                                                  ? "sorting_desc"
                                                  : "sorting",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.daySorting(
                                                    "created_at"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Correo\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "tH",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              class:
                                                (this.$data.daysSortType ==
                                                  "asc" ||
                                                  this.$data.daysSortType ==
                                                    "ASC") &&
                                                this.$data.daysSortBy ==
                                                  "available_days.date"
                                                  ? "sorting_asc"
                                                  : (this.$data.daysSortType ==
                                                      "desc" ||
                                                      this.$data.daysSortBy ==
                                                        "DESC") &&
                                                    this.$data.daysSortBy ==
                                                      "available_days.date"
                                                  ? "sorting_desc"
                                                  : "sorting",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.daySorting(
                                                    "available_days.date"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Fecha\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "tH",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              class:
                                                (this.$data.daysSortType ==
                                                  "asc" ||
                                                  this.$data.daysSortType ==
                                                    "ASC") &&
                                                this.$data.daysSortBy ==
                                                  "available_hours.time"
                                                  ? "sorting_asc"
                                                  : (this.$data.daysSortType ==
                                                      "desc" ||
                                                      this.$data.daysSortBy ==
                                                        "DESC") &&
                                                    this.$data.daysSortBy ==
                                                      "available_hours.time"
                                                  ? "sorting_desc"
                                                  : "sorting",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.daySorting(
                                                    "available_hours.time"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Hora\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Opciones\n                                                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark",
                                      },
                                      _vm._l(_vm.days, function (dayItem) {
                                        return _c(
                                          "tr",
                                          {
                                            key: _vm.day.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { sale: "row" },
                                          },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(dayItem.id) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(dayItem.code) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    dayItem.order.length > 0
                                                      ? dayItem.order[0].email
                                                      : ""
                                                  ) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      dayItem.available_day.date
                                                    )
                                                  ) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    dayItem.available_hour.time
                                                  ) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-light btn-hover-primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editDay(
                                                          dayItem
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          width: "24px",
                                                          height: "24px",
                                                          viewBox: "0 0 24 24",
                                                          fill: "none",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z",
                                                            stroke: "#000000",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right",
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.prev_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchTickets(
                                                  _vm.pagination.prev_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Previous")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" },
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.next_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchTickets(
                                                  _vm.pagination.next_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Next")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.display_form_update_day
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
            class: _vm.display_form_update_day ? "offcanvas-on" : "",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3",
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Información del boleto"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.display_form_update_day = 0
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Código"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", name: "code", disabled: "" },
                      domProps: { value: _vm.updateDay.code },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Correo"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", name: "email", disabled: "" },
                      domProps: { value: _vm.updateDay.order[0].email },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Fecha"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", name: "date", disabled: "" },
                      domProps: {
                        value: _vm.formatDate(_vm.updateDay.available_day.date),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [_vm._v("Hora")]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", name: "time", disabled: "" },
                      domProps: { value: _vm.updateDay.available_hour.time },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Estatus"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", name: "status", disabled: "" },
                      domProps: { value: _vm.updateDay.status },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Ultima actualización"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", name: "status", disabled: "" },
                      domProps: {
                        value: _vm.formatDate2(_vm.updateDay.created_at),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src:
                        "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" +
                        _vm.updateDay.code,
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                                            Boletos\n                                        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }