var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { action: "#", method: "post", id: "form-terms" } },
    [
      _c("div", { staticClass: "form-group row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("label", [_vm._v("Términos y condiciones")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group " },
            [
              _c("vue-editor", {
                model: {
                  value: _vm.terms_conditions,
                  callback: function ($$v) {
                    _vm.terms_conditions = $$v
                  },
                  expression: "terms_conditions",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }