var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "aside aside-left aside-fixed d-flex flex-column flex-row-auto",
        class: _vm.burgerMenu ? "aside-on" : "",
        attrs: { id: "tc_aside" },
      },
      [
        _c(
          "div",
          { staticClass: "brand flex-column-auto", attrs: { id: "tc_brand" } },
          [
            _c(
              "router-link",
              { staticClass: "brand-logo", attrs: { to: "/admin/dashboard" } },
              [
                _c("img", {
                  staticClass: "brand-image",
                  staticStyle: { height: "25px" },
                  attrs: { alt: "Logo", src: "/assets/images/misc/r.png" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "brand-text" }, [
                  _c("img", {
                    staticStyle: { height: "25px" },
                    attrs: { alt: "Logo", src: "/assets/images/misc/logo.png" },
                  }),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "aside-menu-wrapper flex-column-fluid overflow-auto h-100",
            attrs: { id: "tc_aside_menu_wrapper" },
          },
          [
            _c(
              "div",
              {
                staticClass: "aside-menu mb-5",
                attrs: {
                  id: "tc_aside_menu",
                  "data-menu-vertical": "1",
                  "data-menu-scroll": "1",
                  "data-menu-dropdown-timeout": "500",
                },
              },
              [
                _c("div", { attrs: { id: "accordion" } }, [
                  _c("ul", { staticClass: "nav flex-column" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/admin/dashboard" },
                          },
                          [
                            _c("span", { staticClass: "svg-icon nav-icon" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-home",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("polyline", {
                                    attrs: { points: "9 22 9 12 15 12 15 22" },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "nav-text" }, [
                              _vm._v(" Dashboard "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("media")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("media") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#media",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "media",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("media")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" },
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Medios"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("media"),
                              },
                              attrs: {
                                id: "media",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes(
                                  "media-settings"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/media-setting",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Opciones multimedia")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("manage-media")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/media" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Administrar multimedia")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("catalog")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("catalog") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("catalog")
                                },
                              },
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Catalogo"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("catalog"),
                              },
                              attrs: {
                                id: "catalog",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion1" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "product-unit-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-unit",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Unidades de medida")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-attribute-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-attribute",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [
                                                  _vm._v(
                                                    "Atributos de producto"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-variation-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-variation",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [
                                                  _vm._v(
                                                    "Variaciones de producto"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-brand-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-brand",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Marcas de producto")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-category-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-category",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [
                                                  _vm._v(
                                                    "Categorías de producto"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-list"
                                  )
                                    ? _c("li", { staticClass: "nav-item" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              "data-toggle": "collapse",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setChildActive(
                                                  "product"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Productos")]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-chevron-right fa-rotate-90",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "nav flex-column",
                                            style:
                                              _vm.activeChildItem ==
                                                "product" && _vm.toggleChildMenu
                                                ? "display:block"
                                                : "display:none",
                                          },
                                          [
                                            _vm.$parent.permissions.includes(
                                              "product-list"
                                            )
                                              ? _c(
                                                  "li",
                                                  { staticClass: "nav-item" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "nav-link mini-sub-nav-link",
                                                        attrs: {
                                                          to: "/admin/products",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Lista de productos"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "product-manage"
                                            )
                                              ? _c(
                                                  "li",
                                                  { staticClass: "nav-item" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "nav-link mini-sub-nav-link",
                                                        attrs: {
                                                          to: "/admin/add-product",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Añadir producto"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "import-product"
                                            )
                                              ? _c(
                                                  "li",
                                                  { staticClass: "nav-item" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "nav-link mini-sub-nav-link",
                                                        attrs: {
                                                          to: "/admin/verify-purchase-code",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Importar / Exportar"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("stock")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("stock") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("stock")
                                },
                              },
                            },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Stock / Inventario"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("stock"),
                              },
                              attrs: {
                                id: "stock",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion1" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes("stock-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/stocks" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Inventario")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "stock-manage"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/add-stock" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Añadir inventario")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "stock-transfer-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/stock-transfers",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [
                                                  _vm._v(
                                                    "Lista de transferencias de stock"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "stock-transfer-manage"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/stock-transfer",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [
                                                  _vm._v(
                                                    "Añadir transferencia de stock"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          class: { active: _vm.isActive("tickets-stock") },
                          attrs: {
                            "data-toggle": "collapse",
                            href: "javascript:void(0)",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setActive("tickets-stock")
                            },
                          },
                        },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("span", { staticClass: "nav-text" }, [
                            _vm._v("Administración de boletos"),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-chevron-right fa-rotate-90",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "nav-collapse",
                          class: {
                            "collapse nav-collapse":
                              !_vm.isActive("tickets-stock"),
                          },
                          attrs: {
                            id: "tickets-stock",
                            "data-parent": "#accordion",
                          },
                        },
                        [
                          _c("div", { attrs: { id: "accordion1" } }, [
                            _c("ul", { staticClass: "nav flex-column" }, [
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link sub-nav-link",
                                      attrs: { to: "/admin/tickets-stock" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "bi bi-circle",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "10px",
                                                height: "10px",
                                                fill: "currentColor",
                                                viewBox: "0 0 16 16",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "nav-text" }, [
                                        _vm._v("Días disponibles"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link sub-nav-link",
                                      attrs: { to: "/admin/tickets-data" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "bi bi-circle",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "10px",
                                                height: "10px",
                                                fill: "currentColor",
                                                viewBox: "0 0 16 16",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "nav-text" }, [
                                        _vm._v("Boletos"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("sale-order")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("sale") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#order",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("sale")
                                },
                              },
                            },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Ventas / Ordenes"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("sale"),
                              },
                              attrs: {
                                id: "order",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("order-list")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/orders" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Ordenes")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("sale-manage")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/add-sale" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Añadir venta")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("pos")
                                  ? _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link sub-nav-link",
                                          attrs: { href: "/admin/pos" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-circle",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "10px",
                                                    height: "10px",
                                                    fill: "currentColor",
                                                    viewBox: "0 0 16 16",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "nav-text" },
                                            [_vm._v("POS")]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("business-setting")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("setting") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#setting",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("setting")
                                },
                              },
                            },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Configuración del negocio"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("setting"),
                              },
                              attrs: {
                                id: "setting",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion3" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "general-setting"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/bussiness-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingB",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Ajustes generales")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "warehouse-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/warehouse" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Warehouse")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "currency-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/currencies",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Moneda")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "payment-methods-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/payment-methods",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Métodos de pago")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "country-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/country-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Paises")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes("state-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/state-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Estados")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes("city-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/city-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Ciudades")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-boxes font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-boxes font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-boxes font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-clipboard-check font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-cogs font-size-h4" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }