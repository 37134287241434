var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center  border-bottom-dark px-0",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "icons d-flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "btn ml-2 p-0 kt_notes_panel_toggle",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: "",
                                "data-placement": "right",
                                "data-original-title": "Check out more demos",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm ",
                                  on: {
                                    click: function ($event) {
                                      _vm.display_form_add_day =
                                        !_vm.display_form_add_day
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-plus white",
                                      attrs: {
                                        width: "25px",
                                        height: "25px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d: "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 " }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: " table-responsive",
                            attrs: { id: "printableTable" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "sale_wrapper" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "sale_length" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Show \n                                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.daysLimit,
                                              expression: "daysLimit",
                                            },
                                          ],
                                          attrs: { name: "sale_length" },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.daysLimit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                return _vm.fetchDays()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" entries"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "sale_filter" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Search:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.daysSearchParameter,
                                            expression: "daysSearchParameter",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: _vm.daysSearchParameter,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.fetchDays()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.daysSearchParameter =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    this.daysSearchParameter != ""
                                      ? _c(
                                          "button",
                                          {
                                            on: { click: _vm.clearSearchDays },
                                          },
                                          [_vm._v("clear")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: { id: "sale", sale: "grid" },
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c(
                                        "tr",
                                        { attrs: { sale: "row" } },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              class:
                                                (this.$data.daysSortType ==
                                                  "asc" ||
                                                  this.$data.daysSortType ==
                                                    "ASC") &&
                                                this.$data.daysSortBy == "id"
                                                  ? "sorting_asc"
                                                  : (this.$data.daysSortType ==
                                                      "desc" ||
                                                      this.$data.daysSortBy ==
                                                        "DESC") &&
                                                    this.$data.daysSortBy ==
                                                      "id"
                                                  ? "sorting_desc"
                                                  : "sorting",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.daysSorting("id")
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                ID\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                 Fecha\n                                                             "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              class:
                                                (this.$data.daysSortType ==
                                                  "asc" ||
                                                  this.$data.daysSortType ==
                                                    "ASC") &&
                                                this.$data.dasySortBy ==
                                                  "created_at"
                                                  ? "sorting_asc"
                                                  : (this.$data.daysSortType ==
                                                      "desc" ||
                                                      this.$data.daysSortType ==
                                                        "DESC") &&
                                                    this.$data.dasySortBy ==
                                                      "created_at"
                                                  ? "sorting_desc"
                                                  : "sorting",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.daysSorting(
                                                    "created_at"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Total de boletos\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "tH",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Boletos vendidos\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "tH",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              staticStyle: {
                                                width: "53.1891px",
                                              },
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                                "aria-label": "Action",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Horarios activos\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Día gratuito\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Horarios\n                                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "no-sort sorting_disabled",
                                              attrs: {
                                                rowspan: "1",
                                                colspan: "1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                                Opciones\n                                                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark",
                                      },
                                      _vm._l(_vm.days, function (dayItem) {
                                        return _c(
                                          "tr",
                                          {
                                            key: _vm.day.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { sale: "row" },
                                          },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(dayItem.id) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    _vm.formatDate(dayItem.date)
                                                  ) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    dayItem.total_tickets
                                                  ) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(dayItem.sold_tickets) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    dayItem.total_available_hours
                                                  ) +
                                                  "\n                                                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              dayItem.is_free_day == 1
                                                ? _c("span", [_vm._v("Sí")])
                                                : _c("span", [_vm._v("No")]),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c(
                                                "div",
                                                _vm._l(
                                                  dayItem.available_hours,
                                                  function (hour) {
                                                    return _c("div", [
                                                      hour.status === 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatTime(
                                                                      hour.time
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Vendidos: "
                                                                ),
                                                                _c(
                                                                  "strong",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "green",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        hour.soldTickets
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  }
                                                ),
                                                0
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-light btn-hover-primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showDayHours(
                                                          dayItem
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          width: "24px",
                                                          height: "24px",
                                                          viewBox: "0 0 24 24",
                                                          fill: "none",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M12 7V12L14.5 10.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z",
                                                            stroke: "#000000",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-light btn-hover-primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editDay(
                                                          dayItem
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          width: "24px",
                                                          height: "24px",
                                                          viewBox: "0 0 24 24",
                                                          fill: "none",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z",
                                                            stroke: "#000000",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-light btn-hover-primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeDay(
                                                          dayItem.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          width: "24px",
                                                          height: "24px",
                                                          viewBox: "0 0 24 24",
                                                          fill: "none",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6",
                                                            stroke: "#000000",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right",
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.prev_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchDays(
                                                  _vm.pagination.prev_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Previous")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" },
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.next_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchDays(
                                                  _vm.pagination.next_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Next")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.display_form_add_day
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
            class: _vm.display_form_add_day ? "offcanvas-on" : "",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3",
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Agregar días"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.display_form_add_day = 0
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Calendario"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  locale: "es",
                                  "no-title": "",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.day.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.day, "date", $$v)
                                  },
                                  expression: "day.date",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-menu", {
                                ref: "menu",
                                attrs: {
                                  "close-on-content-click": false,
                                  "return-value": _vm.day.date,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "auto",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    return _vm.$set(_vm.day, "date", $event)
                                  },
                                  "update:return-value": function ($event) {
                                    return _vm.$set(_vm.day, "date", $event)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-combobox",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    multiple: "",
                                                    chips: "",
                                                    "small-chips": "",
                                                    label: "Días seleccionados",
                                                    "prepend-icon":
                                                      "mdi-calendar",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: _vm.day.date,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.day,
                                                        "date",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "day.date",
                                                  },
                                                },
                                                "v-combobox",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2538134163
                                ),
                                model: {
                                  value: _vm.menu,
                                  callback: function ($$v) {
                                    _vm.menu = $$v
                                  },
                                  expression: "menu",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.has("date")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("date")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addUpdateDays()
                    },
                  },
                },
                [_vm._v("Guardar")]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display_form_update_day
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
            class: _vm.display_form_update_day ? "offcanvas-on" : "",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3",
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Actualizar día"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.display_form_update_day = 0
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [_vm._v("Día")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.updateDay.date,
                          expression: "updateDay.date",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "date", name: "time" },
                      domProps: { value: _vm.updateDay.date },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.updateDay, "date", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.has("date")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("date")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("¿Acceso gratuito?"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.updateDay.is_free_day,
                            expression: "updateDay.is_free_day",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { name: "is_default" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.updateDay,
                              "is_free_day",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "1" } }, [_vm._v("Sí")]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_free_day")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_free_day")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("¿Habilitado?"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.updateDay.status,
                            expression: "updateDay.status",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { name: "is_default" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.updateDay,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "1" } }, [_vm._v("Sí")]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("status")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("status")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addUpdateDays()
                    },
                  },
                },
                [_vm._v("Guardar")]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display_form_add_hour
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
            class: _vm.display_form_add_hour ? "offcanvas-on" : "",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3",
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Horarios disponibles"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.display_form_add_hour = 0
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              { attrs: { id: "myform" } },
              [
                _vm._l(_vm.hoursForm, function (hourItem) {
                  return _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-around",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Estatus"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: hourItem.status,
                                expression: "hourItem.status",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { transform: "scale(1)" },
                            attrs: { type: "checkbox", name: "set" },
                            domProps: {
                              checked: Array.isArray(hourItem.status)
                                ? _vm._i(hourItem.status, null) > -1
                                : hourItem.status,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = hourItem.status,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        hourItem,
                                        "status",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        hourItem,
                                        "status",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(hourItem, "status", $$c)
                                }
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Hora"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: hourItem.time,
                                expression: "hourItem.time",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "time", name: "time", readonly: "" },
                            domProps: { value: hourItem.time },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(hourItem, "time", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Tickets"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: hourItem.tickets,
                                expression: "hourItem.tickets",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              step: "1",
                              name: "tickets",
                              min: "1",
                            },
                            domProps: { value: hourItem.tickets },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  hourItem,
                                  "tickets",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("label", [_vm._v("Boletos")]),
                          _vm._v(" "),
                          _c("multiselect", {
                            key: hourItem.id,
                            attrs: {
                              options: _vm.products,
                              placeholder: "Selecciona una opción",
                              label: "title",
                              "track-by": "product_id",
                              multiple: true,
                            },
                            model: {
                              value: hourItem.product_ids,
                              callback: function ($$v) {
                                _vm.$set(hourItem, "product_ids", $$v)
                              },
                              expression: "hourItem.product_ids",
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has("product_ids")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("product_ids")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { "margin-top": "2rem" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addUpdateHours()
                          },
                        },
                      },
                      [_vm._v("Guardar")]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\n                                            Stock de tickets\n                                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }