var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "table table-striped  text-body" },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.selectedProducts,
                                  function (selectedProduct, index) {
                                    return _c("tr", {}, [
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(selectedProduct.title)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: " text-center" },
                                        [
                                          _c("input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Enter Quantity",
                                              disabled: "",
                                            },
                                            domProps: {
                                              value: selectedProduct.qty,
                                            },
                                          }),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                                            Sale Return Detail\n                                        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("Name")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("Quantity")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }